import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  physical,
  sst,
}) => {
  const [selected, setSelected] = useState(null);

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input type="hidden" name="product_ids" value={[selected]} />

        <ProductCard
          cardTitle={"MCOR 2025"}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              // setSelectedGalaDinner(null);
            }
          }}
          handleRadio={() => {
            // setSelectedGalaDinner(null);
          }}
        />

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 8% SST
          </strong>
        )}
        {/* for international delegate is compulsory to select one of the precongress */}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={
            selected === null
            // purchasedPhysical
            //   ? purchasedPhysical && selectedGalaDinner === null
            //   : (selected === null && selectedGalaDinner === null) ||
            //     selected === null
          }
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
